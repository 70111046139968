import React, { useContext, useEffect, useState } from "react";
import { walletConnectContext } from "../../Context/WalletConnectContext";
import { useParams } from "react-router-dom";
import axios from 'axios';

export default function Stake() {
  const { link_ref_address } = useParams();

  const { open, dbuser, account, copyaddress, connectMetamask, toastError, getudata, library, settings, esigner, tokenrate, getuserDB, disconnect, ethers, toastSuccess } = useContext(walletConnectContext);
  const [amount, setamount] = useState(0);
  const [token, settoken] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [checked, setchecked] = useState(false);
  const [rate, setrate] = useState(0);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");

  const onDeposit = async () => {
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    if (dbuser === null) {
      var data = await getudata(referral);
      if (data.data.error) {
        toastError("Invalid Referral Address");
        settrdone(false);
        return "";
      }
      if (data.data.data.status === false) {
        toastError("Referral not active");
        settrdone(false);
        return "";
      }
    } else if (dbuser.restake === false) {
      toastError("You cannot restake now");
      settrdone(false);
      return "";
    }
    // if (!tandc) {
    //     toastError("Accept terms and conditions");
    //     settrdone(false);
    //     return;
    // }

    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );
    settrdone(true);
    async function main() {

      try {

        var stk = await contract.stake(
          library.utils.parseEther(`${amount}`),
          referral,
        );

        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);

        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            // hash: randomHash,
            hash: receipt.transactionHash,
            token: token,
            amount: amount,
            rate: tokenrate,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            getuserDB();
            toastSuccess("Transaction done");
            settrdone(false);
          });


      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }

    }
    if (Number(library.utils.formatEther(allowance)) < amount) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          library.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [link_ref_address, dbuser, account]);

  return (
    <>
      {dbuser === null ?
        <div className="buy_crypto__formarea-group mb-5 mb-md-6">
          <label className="mb-2">Referral </label>
          <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
            <input type="text" placeholder="Enter Referral Address" value={referral} onChange={(e) => { setreferral(e.target.value); }} />
          </div>
        </div> : ''}
      <div className="buy_crypto__formarea-group mb-5 mb-md-6">
        <label className="mb-2">Amount</label>
        <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
          <input type="text" placeholder="0" onChange={(e) => setamount(Number(e.target.value))} />
        </div>
      </div>
      <div className="d-flex align-items-center gap-1 mb-5 mb-md-6 justify-content-between flex-wrap">
        <span>Token:</span>
        <span>{amount} USDT</span>
      </div>

      {trdone ? (
        <div className='text-center'>
          <img
            className="border-0"
            src="../assets/img/loader.gif"
            alt="loading"
            height={100}
            width={100}
          />
        </div>
      ) : (
        <a type="button" className="cmn-btn py-3 px-5 px-md-6 d-block " onClick={() => onDeposit()}>
          Start saving
        </a>
      )}

    </>
  );
}
