import React, { useState } from "react";
import { Link } from "react-router-dom";
export default function DashboardSidebar({ showMenu, setShowMenu }) {

  const currentPath = window.location.pathname;

  return (
    <>
      <div className="sidebar open d-lg-block d-md-none  d-sm-none d-none">
        <div className="logo-details">
          {/* <i className="bx bxl-codepen icon" /> */}
          <div className="logo_name text-center header-fixed ">
            {" "}
            <Link to="/">
              <img
                src="../assets/img/levish_logo.png"
                width="150"
                className="my-5 "
                alt="levish"
              />
            </Link>
          </div>
          <i className="bx bx-menu" id="btn" />
        </div>
        <ul className="nav-list">
          <li className="mt-5">
            <Link
              to="/dashboard"
              className={`${currentPath === "/dashboard"
                ? "d-flex justify-content-start active"
                : "d-flex justify-content-start "
                }`}
            >
              <span className="links_name">Dashboard</span>
            </Link>
            <span className="tooltip">Dashboard</span>
          </li>
          <li className="">
            <Link
              to="/staking"
              className={`${currentPath === "/staking"
                ? "d-flex justify-content-start active"
                : "d-flex justify-content-start "
                }`}
            >
              <span className="links_name">Investment</span>
            </Link>
            <span className="tooltip">Investment</span>
          </li>
          <li>
            <Link
              to="/team"
              className={`${currentPath === "/team"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Teams</span>
            </Link>
            {/* <span className="tooltip">Invest</span> */}
          </li>
          <li>
            <Link
              to="/reward"
              className={`${currentPath === "/reward"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Daily Reward</span>
            </Link>
            {/* <span className="tooltip">Invest</span> */}
          </li>
          <li>
            <Link
              to="/direct-reward"
              className={`${currentPath === "/direct-reward"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Direct Reward</span>
            </Link>
          </li>
          <li>
            <Link
              to="/level-reward"
              className={`${currentPath === "/level-reward"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Level Reward</span>
            </Link>
          </li>
          <li>
            <Link
              to="/salary-reward"
              className={`${currentPath === "/salary-reward"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Salary Reward</span>
            </Link>
          </li>
          <li>
            <Link
              to="/reward-income"
              className={`${currentPath === "/reward-income"
                ? "d-flex justify-content-start active py-0"
                : "d-flex justify-content-start py-0"
                }`}
            >
              <span className="links_name">Reward</span>
            </Link>
            {/* <span className="tooltip">Invest</span> */}
          </li>

          <li>
            <Link
              to="/witndwaral"
              className={`${currentPath === "/witndwaral"
                ? "d-flex justify-content-between active py-0"
                : "d-flex justify-content-between py-0"
                }`}
            >
              <span className="links_name">Witndwaral</span>
            </Link>
            <span className="tooltip">Witndwaral</span>
          </li>
        </ul>
      </div>
      {showMenu ? (
        <>
          <div className="sidebar open">
            <div className="logo-details">
              {/* <i className="bx bxl-codepen icon" /> */}
              <div className="logo_name text-center header-fixed ">
                {" "}
                <Link to="/">
                  <img
                    src="../assets/img/levish_logo.png"
                    width="150"
                    className="my-5 "
                    alt="Bame"
                  />
                </Link>
              </div>
              <i className="bx bx-menu" id="btn" />
            </div>
            <ul className="nav-list">
              <li className="mt-5">
                <Link
                  to="/dashboard"
                  className={`${currentPath === "/dashboard"
                    ? "d-flex justify-content-start active"
                    : "d-flex justify-content-start "
                    }`}
                >
                  <span className="links_name">Dashboard</span>
                </Link>
                <span className="tooltip">Dashboard</span>
              </li>
              <li className="">
                <Link
                  to="/staking"
                  className={`${currentPath === "/staking"
                    ? "d-flex justify-content-start active"
                    : "d-flex justify-content-start "
                    }`}
                >
                  <span className="links_name">Investment</span>
                </Link>
                <span className="tooltip">Investment</span>
              </li>
              <li>
                <Link
                  to="/team"
                  className={`${currentPath === "/team"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Teams</span>
                </Link>
                {/* <span className="tooltip">Invest</span> */}
              </li>
              <li>
                <Link
                  to="/daily-reward"
                  className={`${currentPath === "/daily-reward"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Daily Reward</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/direct-reward"
                  className={`${currentPath === "/direct-reward"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Direct Reward</span>
                </Link>
                {/* <span className="tooltip">Invest</span> */}
              </li>
              <li>
                <Link
                  to="/level-reward"
                  className={`${currentPath === "/level-reward"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Level Reward</span>
                </Link>
                {/* <span className="tooltip">Invest</span> */}
              </li>
              <li>
                <Link
                  to="/salary-reward"
                  className={`${currentPath === "/salary-reward"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Salary Reward</span>
                </Link>
                {/* <span className="tooltip">Invest</span> */}
              </li>
              <li>
                <Link
                  to="/reward-income"
                  className={`${currentPath === "/reward-income"
                    ? "d-flex justify-content-start active py-0"
                    : "d-flex justify-content-start py-0"
                    }`}
                >
                  <span className="links_name">Reward</span>
                </Link>
                {/* <span className="tooltip">Invest</span> */}
              </li>


              <li>
                <Link
                  to="/withdrawal"
                  className={`${currentPath === "/withdrawal"
                    ? "d-flex justify-content-between active py-0"
                    : "d-flex justify-content-between py-0"
                    }`}
                >
                  <span className="links_name">Withdrawal</span>
                </Link>
                <span className="tooltip">Withdrawal</span>
              </li>
            </ul>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
